import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// images
import banner_img from "../../images/undersidor/e-faktura.svg";
import peppol from '../../images/peppol_icon.png';
import e_faktura from '../../images/e_faktura.png';
import edi from '../../images/edi.png';

const KONTAKT_BOT = "botxWuWV";
const DEMO_BOT = "WfgujSpL";

function E_faktura() {
    const [kontakt_1, set_kontakt_1] = useState(false);
    const [kontakt_2, set_kontakt_2] = useState(false);
    const [demo_1, set_demo_1] = useState(false);
    const [demo_2, set_demo_2] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>E-faktura | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Ta emot och skicka enkelt e-fakturor oavsett format med Crediflow. " />
                <meta name="keywords" content="Betalningar P27" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Ta emot och skicka enkelt e-fakturor oavsett format med Crediflow." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/leverantorsfakturor/e_faktura" />
                <link rel="cannonical" href="https://www.crediflow.se/leverantorsfakturor/e_faktura" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">E-faktura, EDI och/eller PEPPOL?</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Känns alla dessa ord krångliga och förvirrande?
                                        Kanske har er leverantör bett om att få skicka 
                                        en EDI-faktura till Er? Troligen så menar 
                                        din kund e-faktura men säger EDI. Låt oss 
                                        hjälpa er, glöm alla busswords och 
                                        format och fokusera på ert företag istället!
                                    </p>
                                    <button onClick={() => set_kontakt_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {kontakt_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={KONTAKT_BOT} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Hem</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <Link to="/leverantorsfakturor" className="flex items-center text-blue-800 hover:text-blue-600">leverantörsfakturor</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">e-faktura</span>
                    </div>
                </div>
            </section>



            <section className="bg-gray-100 pt-12" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">E-faktura</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                För inkommande fakturor anpassar vi oss 
                                efter era leverantörers möjligheter, vi 
                                sköter konverteringen av format samt 
                                validerar information som är viktig för 
                                er. Ni får alla fakturor enhetligt 
                                oavsett hur leverantörerna skickat dom.
                            </p>
                            <button onClick={() => set_demo_1(true)} className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                            
                            {demo_1 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={DEMO_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                
                        <div className="w-2/3 p-4 mx-auto">
                            <img className="w-full" src={e_faktura} alt="e-faktura" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={peppol} alt="peppol" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">PEPPOL</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Vi är aktiva medlemmar i OPENPEPPOL och tillhandahåller 
                                Accesspunkt samt agerar SMP-register för våra kunder.
                                <br/><br/>
                                Vi hjäper er med alla frågor kring Peppol.
                                <br/><br/>
                                Peppol gör det möjligt för handelspartner 
                                att utbyta standardbaserade elektroniska 
                                dokument över Peppol-nätverket som är 
                                baserat på en 4-hörnsmodell.
                            </p>
                            <button onClick={() => set_kontakt_2(true)} className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                            {kontakt_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={KONTAKT_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>

                    </div>
                </div>
            </section>

            <section className="bg-gray-100 py-28" id="skickaFaktura_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">EDI</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Vi erbjuder en helhetslösning för 
                                EDI oavsett om ni vill skicka eller ta emot EDI-meddelanden.
                                <br/><br/>
                                Ingen dubbelregistrering på någon extern webbplats behövs.
                            </p>
                            <div className="mt-10 flex">
                                <button onClick={() => set_demo_2(true)} className="px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Boka Demo</button>
                                <Link to="/edi" target="_blank" rel="noreferrer"className="ml-3 px-10 py-2 rounded-full text-blue-custome border border-blue-custome bg-white shadow-md hover:bg-blue-100">Läs mer</Link>
                            </div>
                            {demo_2 
                                ? 
                                    <div className="h-full w-full mt-10">
                                        <InPageBot code={DEMO_BOT} seamless/>
                                    </div> 
                                : "" 
                            }
                        </div>
                    <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={edi} alt="edi" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default E_faktura;